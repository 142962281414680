import { useCallback, useState } from 'react';
import { Box, Button, ScreenReadersOnly, Stack } from '@customink/pigment-react';
import { useAuth, useAuthUser } from '~/contexts/Auth/AuthContext';
import { LabelValueRow, LabelValueTable } from '~/pages/Settings/components/LabelValueTable';
import { ChangeNameDialog } from '~/pages/Settings/tabs/Profile/ChangeNameDialog';
import { sendNameModalEvent } from '~/pages/Settings/tabs/Profile/profileTracking';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { PasswordChangeProfiles } from '~/pages/Settings/tabs/Profile/PasswordChangeProfiles';
import { PasswordChangeAuth0 } from '~/pages/Settings/tabs/Profile/PasswordChangeAuth0';
import { ChangeTelephoneDialog } from '~/pages/Settings/tabs/Profile/ChangeTelephoneDialog';
import { DeletePhoneButton } from '~/pages/Settings/tabs/Profile/DeletePhoneButton';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { useRollbar } from '@rollbar/react';

export function UserProfile() {
    const { email } = useAuthUser();
    const rollbar = useRollbar();
    const isPhoneNumberFF = useFeatureFlagEnabled('update_phone_number');

    const [dialogNameOpen, setDialogNameOpen] = useState(false);
    const [dialogTelephoneOpen, setDialogTelephoneOpen] = useState(false);

    const { identityProvider } = useAuth();
    const isProfilesIdentityProvider = identityProvider === 'profiles';

    const { user: userData } = useUserAccount();

    const openEditName = useCallback(() => {
        sendNameModalEvent('Open');
        setDialogNameOpen(true);
    }, []);
    const closeEditName = useCallback(() => {
        sendNameModalEvent('Close');
        setDialogNameOpen(false);
    }, []);

    if (!userData) {
        return <Box mb={3}>No User Profile has been found for this Account.</Box>;
    }

    return (
        <>
            <Stack>
                <ScreenReadersOnly>
                    <h2>Profile</h2>
                </ScreenReadersOnly>
                <LabelValueTable>
                    <LabelValueRow
                        label="Name"
                        value={
                            <Box
                                component="span"
                                data-testid="profile name"
                            >{`${userData.firstName} ${userData.lastName}`}</Box>
                        }
                        action={
                            <Button data-testid="profile set name button" onClick={openEditName} variant="secondary">
                                {userData.firstName && userData.lastName ? 'Change Name' : 'Set Your Name'}
                            </Button>
                        }
                    />
                    {isPhoneNumberFF && (
                        <LabelValueRow
                            label="Phone Number"
                            value={
                                <Box component="span" data-testid="profile telephone">
                                    {userData.telephone}
                                </Box>
                            }
                            action={
                                <Stack
                                    sx={{
                                        flexDirection: { xs: 'column', md: 'row' },
                                    }}
                                    gap={1}
                                >
                                    {userData.telephone && <DeletePhoneButton userId={userData.id} />}
                                    <Button
                                        data-testid="profile set telephone button"
                                        onClick={() => setDialogTelephoneOpen(true)}
                                        variant="secondary"
                                    >
                                        {userData.telephone ? 'Change' : 'Set Phone Number'}
                                    </Button>
                                </Stack>
                            }
                        />
                    )}
                    <LabelValueRow label="Email" value={email} />
                    {isProfilesIdentityProvider ? <PasswordChangeProfiles /> : <PasswordChangeAuth0 />}
                </LabelValueTable>
            </Stack>
            <ChangeNameDialog
                open={dialogNameOpen}
                onClose={closeEditName}
                currentFirstName={userData.firstName}
                currentLastName={userData.lastName}
                userId={userData.id}
            />
            <ChangeTelephoneDialog
                open={dialogTelephoneOpen}
                onClose={() => setDialogTelephoneOpen(false)}
                userId={userData.id}
            />
        </>
    );
}
