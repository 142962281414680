import { parsePhoneNumber, getCountryCodeForRegionCode } from 'awesome-phonenumber';

/**
 * Sanitize phone number by trimming and changing calling code prefix 00 to +
 * This is needed because awesome-phonenumber does not understand 00 prefix
 * @param telephone The input telephone number
 */
export const sanitizeTelephone = (telephone: string) => telephone.trim().replace(/^\+?00/, '+');

/**
 * Parse telephone number using awesome-phonenumber, and return formatted number and parsedPhoneNumber object.
 * @param telephone The input telephone number, which may be invalid, and with or without a calling code.
 * @param expectedRegionCode Expected ISO region code, so that a number without calling code may be parsed.
 */
export const parseAndFormatTelephone = (telephone: string, expectedRegionCode?: string) => {
    const sanitizedTelephone = sanitizeTelephone(telephone);
    const parsedPhoneNumber = parsePhoneNumber(sanitizedTelephone, { regionCode: expectedRegionCode });
    const formattedTelephone = parsedPhoneNumber.number?.international ?? sanitizedTelephone;
    return { formattedTelephone, parsedPhoneNumber };
};

/**
 * Replace the calling code of a telephone number with a new calling code, or adds it if none is present.
 *
 * @param telephone The input telephone number, which may be invalid, and with or without a calling code.
 * @param newCallingCode The new calling code that the number shall have.
 * */
export const replaceTelephoneCallingCode = (telephone: string, newCallingCode: number) => {
    const sanitizedTelephone = sanitizeTelephone(telephone);
    const parsedPhoneNumber = parsePhoneNumber(sanitizedTelephone);
    const parsedRegionCode = parsedPhoneNumber.regionCode;

    // if no calling code was detected, add the new one
    if (!parsedRegionCode) {
        return `+${newCallingCode}${sanitizedTelephone}`;
    }

    // if parsePhoneNumber detected a calling code, replace it with a new one
    // parsedPhoneNumber returns a property `countryCode`, which is already the calling code
    // but for some reason, the lib does not always set it, even though it does detect the calling code and returns `regionCode` (the ISO country code)
    // that's why calling code has to be obtained from ISO country code instead of getting it directly from return
    const parsedCallingCode = getCountryCodeForRegionCode(parsedRegionCode);
    return sanitizedTelephone.replace(`+${parsedCallingCode}`, `+${newCallingCode}`);
};

const allPossibleUnicodeDashesMatcher = /[\s\u2010-\u2015\u2212\uFE58\uFE63\uFF0D-]/g;
export const removeDashes = (telephone: string) => telephone.replace(allPossibleUnicodeDashesMatcher, '');
