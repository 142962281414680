import { Button } from '@customink/pigment-react';
import { Design } from '~/adapters/services/accounts/designs/types';
import { internalBuyLink } from '~/adapters/services/rfe/links';

interface ReorderDesignButtonProps {
    design: Design;
}

export function InternalBuyButton({ design }: ReorderDesignButtonProps) {
    const buttonLabel = design.mostRecentOrderId ? 'Internal Reorder' : 'Internal Checkout';

    return (
        <Button
            href={internalBuyLink(design.designId.toString(), design.compositeId, Number(design.mostRecentOrderId))}
            variant="secondary"
            fullWidth
            data-action="saved designs buy" // used by selenium_ui_tests
        >
            {buttonLabel}
        </Button>
    );
}
