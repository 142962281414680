import { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthReceiver } from '~/AuthReceiver';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { AppWideErrorLayout } from '~/components/Layout/AppWideErrorLayout';
import { Auth0Layout } from '~/components/Layout/Auth0Layout';
import { UserAccountProvider } from '~/contexts/UserAccount/UserAccountProvider';
import { Auth0Error } from '~/pages/Auth0Error/Auth0Error';
import { FeedbackErrorBoundary } from '~/pages/Feedback/FeedbackErrorBoundary';
import { LoadingTaxExemptions } from '~/pages/Loading/LoadingTaxExemptions';
import { PageNotFound } from '~/pages/PageNotFound';
import { SignalerFlags } from '~/pages/Signaler/SignalerFlags';
import { SocialDeletionRequest } from '~/pages/SocialDeletionRequest/SocialDeletionRequest';
import { VerifyUser } from '~/pages/VerifyUser/VerifyUser';
import { GreyPageWithCenteredContainer } from '~/components/Layout/GreyPageWithCenteredContainer';
import { Provider } from '@rollbar/react';
import { rollbar } from '~/adapters/rollbar';
import { AppProviders } from './AppProviders';
import { EmptyLayout } from './components/Layout/EmptyLayout';
import { FullLayout } from './components/Layout/FullLayout';
import { ProtectedWithLayout } from './components/Layout/ProtectedWithLayout';
import { ProtectedCodeOrUser } from './components/Protected/ProtectedCodeOrUser';
import {
    AccountPathsGroup,
    ArtsPath,
    BaseContext,
    DesignsPath,
    FeedbackPath,
    FlagsPath,
    GroupOrdersPath,
    OrdersPath,
    RedirectingTaxExemptionPath,
    SharedDesignsPath,
    SingleOrderPath,
    SocialDeletionRequestPath,
    StoresPath,
} from './config';
import { DesignsAndArts } from './pages/DesignsAndArts';
import { Feedback } from './pages/Feedback/Feedback';
import GroupOrders from './pages/GroupOrders/GroupOrders';
import OrderHistory from './pages/OrderHistory/OrderHistory';
import OrderHistorySingle from './pages/OrderHistory/OrderHistorySingle';
import { DesignDetail } from './pages/SavedDesigns/DesignDetail/DesignDetail';
import { Account } from './pages/Settings/Account';
import { SharedDesigns } from './pages/SharedDesigns/SharedDesigns';
import { Stores } from './pages/Stores/Stores';

export default function App() {
    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.href = `${BaseContext}${DesignsPath}`;
        }
    }, []);

    return (
        <Provider instance={rollbar}>
            <ErrorBoundary withLayout errorMessage="An error ocurred while loading the accounts portal">
                <Router basename={BaseContext}>
                    <AppProviders>
                        <ErrorBoundary withLayout errorMessage="An error ocurred while loading this page">
                            <Routes>
                                <Route path="/" element={<Navigate to={DesignsPath} />} />
                                <Route
                                    path={`${DesignsPath}/:compositeId`}
                                    element={
                                        <UserAccountProvider>
                                            <EmptyLayout>
                                                <DesignDetail />
                                            </EmptyLayout>
                                        </UserAccountProvider>
                                    }
                                />
                                <Route
                                    path={SingleOrderPath}
                                    element={
                                        <ProtectedWithLayout layoutComponent={EmptyLayout}>
                                            <OrderHistorySingle />
                                        </ProtectedWithLayout>
                                    }
                                />
                                {[DesignsPath, ArtsPath].map((path) => (
                                    <Route
                                        // prevent unnecessary renders https://stackoverflow.com/questions/69921188/react-router-dom-v6-pass-array-to-path
                                        key="ConstantDesignsAndArtsPathKey"
                                        path={path}
                                        element={
                                            <ProtectedWithLayout layoutComponent={FullLayout}>
                                                <DesignsAndArts />
                                            </ProtectedWithLayout>
                                        }
                                    />
                                ))}
                                <Route
                                    path={SharedDesignsPath}
                                    element={
                                        <ProtectedWithLayout layoutComponent={FullLayout}>
                                            <SharedDesigns />
                                        </ProtectedWithLayout>
                                    }
                                />
                                <Route
                                    path={GroupOrdersPath}
                                    element={
                                        <ProtectedWithLayout layoutComponent={FullLayout}>
                                            <GroupOrders />
                                        </ProtectedWithLayout>
                                    }
                                />
                                <Route
                                    path={OrdersPath}
                                    element={
                                        <ProtectedWithLayout layoutComponent={FullLayout}>
                                            <OrderHistory />
                                        </ProtectedWithLayout>
                                    }
                                />
                                <Route
                                    path={StoresPath}
                                    element={
                                        <ProtectedWithLayout layoutComponent={FullLayout}>
                                            <Stores />
                                        </ProtectedWithLayout>
                                    }
                                />
                                {AccountPathsGroup.map((path) => (
                                    <Route
                                        // prevent unnecessary renders https://stackoverflow.com/questions/69921188/react-router-dom-v6-pass-array-to-path
                                        key="ConstantAccountPathKey"
                                        path={path}
                                        element={
                                            <ProtectedWithLayout layoutComponent={FullLayout}>
                                                <Account />
                                            </ProtectedWithLayout>
                                        }
                                    />
                                ))}
                                <Route
                                    path={FlagsPath}
                                    element={
                                        <ProtectedWithLayout layoutComponent={FullLayout}>
                                            <SignalerFlags />
                                        </ProtectedWithLayout>
                                    }
                                />

                                <Route
                                    path="/auth/verify"
                                    element={
                                        <Auth0Layout>
                                            <VerifyUser />
                                        </Auth0Layout>
                                    }
                                />
                                <Route
                                    path="/auth/error"
                                    element={
                                        <Auth0Layout>
                                            <Auth0Error />
                                        </Auth0Layout>
                                    }
                                />
                                <Route path="/auth" element={<AuthReceiver />} />
                                <Route
                                    path={FeedbackPath}
                                    element={
                                        <ProtectedCodeOrUser>
                                            <UserAccountProvider>
                                                <EmptyLayout>
                                                    <FeedbackErrorBoundary>
                                                        <Feedback />
                                                    </FeedbackErrorBoundary>
                                                </EmptyLayout>
                                            </UserAccountProvider>
                                        </ProtectedCodeOrUser>
                                    }
                                />
                                <Route
                                    path={RedirectingTaxExemptionPath}
                                    element={
                                        <EmptyLayout>
                                            <LoadingTaxExemptions />
                                        </EmptyLayout>
                                    }
                                />
                                <Route
                                    path={SocialDeletionRequestPath}
                                    element={
                                        <GreyPageWithCenteredContainer>
                                            <SocialDeletionRequest />
                                        </GreyPageWithCenteredContainer>
                                    }
                                />
                                <Route
                                    path="/*"
                                    element={
                                        <AppWideErrorLayout>
                                            <PageNotFound />
                                        </AppWideErrorLayout>
                                    }
                                />
                            </Routes>
                        </ErrorBoundary>
                    </AppProviders>
                </Router>
            </ErrorBoundary>
        </Provider>
    );
}
