import { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@customink/pigment-react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Loader } from '~/components/Loader/loader';
import { useUpdateUserData } from '~/adapters/services/accounts/users/hooks';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRegisterInput } from '~/adapters/react-hook-form/registerInput';
import { removeDashes } from '~/adapters/telephone/telephone';
import { useRollbar } from '@rollbar/react';

interface ChangeTelephoneDialogProps {
    open: boolean;
    onClose: EmptyFunction;
    currentTelephone?: string | null;
    userId: number;
}

interface ChangeTelephoneForm {
    telephone: string;
}

export function ChangeTelephoneDialog({ currentTelephone, userId, onClose, open }: ChangeTelephoneDialogProps) {
    const { mutate, isPending } = useUpdateUserData();
    const rollbar = useRollbar();

    const mutateAndNotify = useCallback<SubmitHandler<ChangeTelephoneForm>>(
        ({ telephone }) => {
            mutate(
                { telephone: telephone === '' ? null : telephone, userId },
                {
                    onSettled: onClose,
                    onError: (error) => {
                        pushApiErrorNotification('Your phone number could not be updated right now.');
                        rollbar.error(error);
                    },
                    onSuccess: () => {
                        pushSuccessNotification(`Phone number was ${telephone === '' ? 'removed' : 'updated'}.`);
                    },
                },
            );
        },
        [mutate, userId, onClose],
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangeTelephoneForm>({ defaultValues: { telephone: currentTelephone ?? '' } });
    const registerInput = useRegisterInput(register);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title="Change Phone Number"
            width="md"
            mobileVariant="fullScreen"
            disableRestoreFocus
        >
            <DialogContent pt={2}>
                <form id="change-telephone-form" onSubmit={handleSubmit(mutateAndNotify)}>
                    <TextField
                        label="Phone Number"
                        inputProps={{ autoComplete: 'tel', 'data-testid': 'profile phone number textfield' }}
                        fullWidth
                        error={!!errors.telephone}
                        helperText={errors.telephone?.message}
                        autoFocus
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...registerInput('telephone', {
                            pattern: {
                                value: /^\d{10}$/,
                                message: 'Please enter exactly 10 digits, without country code',
                            },
                            setValueAs: removeDashes,
                        })}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="secondary">
                    Cancel
                </Button>
                <Button
                    data-testid="change profile phone number dialog button"
                    disabled={isPending}
                    type="submit"
                    form="change-telephone-form"
                >
                    {isPending ? <Loader title="Saving..." /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
