import { Alert, Box, Button, Divider, ScreenReadersOnly, Stack } from '@customink/pigment-react';
import { useCallback, useState } from 'react';
import { useAuth, useAuthUser } from '~/contexts/Auth/AuthContext';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { ImpersonateOnlyBanner } from '~/pages/Settings/components/ImpersonateOnlyBanner';
import { AccountIdRow } from '~/pages/Settings/components/AccountIdRow';
import { LabelValueRow, LabelValueTable } from '../../components/LabelValueTable';
import { ChangeNameDialog } from './ChangeNameDialog';
import { PasswordChangeLinks } from './PasswordChangeLinks';

export function AdminProfile() {
    const { identityProvider } = useAuth();
    const isProfilesIdentityProvider = identityProvider === 'profiles';

    const { email } = useAuthUser();
    const { user: userData } = useUserAccount();
    const [dialogName, setDialogName] = useState(false);

    const openEditName = useCallback(() => {
        setDialogName(true);
    }, []);
    const closeEditName = useCallback(() => {
        setDialogName(false);
    }, []);

    // valid returnable type, in case of 404 Not Found
    if (userData === null) {
        return (
            <>
                <ImpersonateOnlyBanner sx={{ mt: 5, mb: 3 }} />
                <Box mb={3}>No User Profile has been found for this Account.</Box>
                <AccountIdRow />
            </>
        );
    }
    // api failure (not 404)
    if (userData === undefined) {
        return (
            <Alert severity="error">
                There was a problem fetching impersonated User Profile.
                <br />
                Please try again later.
            </Alert>
        );
    }

    return (
        <Stack>
            <ScreenReadersOnly>
                <h2>Profile</h2>
            </ScreenReadersOnly>
            <LabelValueTable>
                <LabelValueRow
                    label="Name"
                    value={`${userData.firstName} ${userData.lastName}`}
                    action={
                        <Button onClick={openEditName} variant="secondary">
                            {userData.firstName && userData.lastName ? "Change user's name" : "Set user's name"}
                        </Button>
                    }
                />
                <LabelValueRow label="Email" value={email} />
            </LabelValueTable>
            <Divider sx={{ mt: 2 }} />
            <ImpersonateOnlyBanner sx={{ mt: 5, mb: 3 }} />
            <LabelValueTable>
                {isProfilesIdentityProvider ? (
                    <PasswordChangeLinks />
                ) : (
                    <Box mb={3}>Password reset through account settings is not available with auth0.</Box>
                )}
                <AccountIdRow />
            </LabelValueTable>
            <ChangeNameDialog
                open={dialogName}
                onClose={closeEditName}
                currentFirstName={userData.firstName}
                currentLastName={userData.lastName}
                userId={userData.id}
            />
        </Stack>
    );
}
