import { useCallback, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Button } from '@customink/pigment-react';
import { trackEvent } from '~/adapters/tracking/tracking';
import { AnalyticsConstants } from '~/adapters/tracking/AnalyticsConstants';
import { Design } from '~/adapters/services/accounts/designs/types';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import setLocation from '~/adapters/browser/setLocation';
import { useAddToCartOrRedirect } from '~/adapters/services/rfe/hooks';
import { useDesignQuote, useProductDetails } from '~/adapters/services/accounts/designs/hooks';
import { checkout, reorderLink } from '~/adapters/services/rfe/links';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { gaCategoryOld, gaCreateDimension, trackGA4AddToCart } from '../tracking';

interface ExternalBuyButtonProps {
    design: Design;
    cardIndex: number;
    handleCloseMenu: EmptyFunction;
    showAddToCartSnackbar: (message: string) => void;
}

export function ExternalBuyButton({
    design,
    cardIndex,
    handleCloseMenu,
    showAddToCartSnackbar,
}: ExternalBuyButtonProps) {
    const addToCartNotificationEnabled = useFeatureFlagEnabled('add_to_cart_notification_v0');

    const reorderAddToCartEnabled = useFeatureFlagEnabled('reorder_add_to_cart_v0');

    const shouldReorder = Boolean(design.mostRecentOrderId);

    const [buyWaiting, setBuyWaiting] = useState(false);

    // chaining of productDetails query on quote query is purely pragmatic decision to help with caching on backend, where
    // both queries depend on designDetails, and having productDetails delayed allows backend to reuse the cached designDetails
    // this is only viable because productDetails are very unimportant (only for GA4 data layer)
    const { data: quoteData, isLoading: isProductDetailsLoading } = useDesignQuote(design.compositeId);
    const { data: productDetailsData } = useProductDetails(design.compositeId, !isProductDetailsLoading);
    const addToCartOrRedirect = useAddToCartOrRedirect();

    const buttonLabel = shouldReorder ? 'Reorder' : 'Add to Cart';

    const navigateToBuy = useCallback(async () => {
        trackEvent({
            category: gaCategoryOld,
            action: 'Button Click',
            label: buttonLabel,
            dimension: {
                ...gaCreateDimension(cardIndex),
                [AnalyticsConstants.isDesignsOrdered]: design.isLocked,
            },
        });
        trackGA4AddToCart(design, cardIndex, quoteData, productDetailsData);
        handleCloseMenu();

        // following lines will cause redirect
        setBuyWaiting(true);
        if (shouldReorder) {
            setLocation(reorderLink(Number(design.mostRecentOrderId), reorderAddToCartEnabled));
            return;
        }

        await addToCartOrRedirect([design.compositeId]);
        setBuyWaiting(false);
        showAddToCartSnackbar(`'${design.designName}' was added to your cart.`);
        // Redirect as usual if the feature flag is disabled
        if (!addToCartNotificationEnabled) {
            setLocation(checkout());
        }
    }, [quoteData, addToCartOrRedirect, productDetailsData, design, cardIndex, buttonLabel]);

    return (
        <Button
            variant="secondary"
            fullWidth
            onClick={navigateToBuy}
            disabled={buyWaiting}
            data-action="saved designs buy" // used by selenium_ui_tests
        >
            {buyWaiting ? <CircularProgress color="secondary" size={24} /> : buttonLabel}
        </Button>
    );
}
