import { Link } from '@customink/pigment-react';
import { useUserSocialDeletionRequest } from '~/adapters/services/accounts/users/hooks';
import { getEnv } from '~/adapters/config/env';
import { format } from 'date-fns';

type UserSocialDeletionRequestResult = ReturnType<typeof useUserSocialDeletionRequest>['data'];

const createTextWithLink = (textBefore: string, linkText: string, textAfter: string, href: string) => (
    <>
        {textBefore}
        <Link href={href} color="primary">
            {linkText}
        </Link>
        {textAfter}
    </>
);

const getParagraph2 = (date: Date, processed: boolean) =>
    `Your request to delete data associated with our Facebook app from ${format(date, 'MMMM d, yyyy')} ${
        processed ? 'has been completed.' : 'is being processed.'
    }`;
export interface SocialDeletionRequestText {
    title: string;
    chip?: {
        label: string;
        color: string;
    };
    paragraph1: string;
    paragraph2: string;
    paragraph3: string | JSX.Element;
    paragraph4?: string | JSX.Element;
}

export function useSocialDeletionRequestText(
    data: UserSocialDeletionRequestResult,
    isLoading: boolean,
): SocialDeletionRequestText {
    const contactUsLink = createTextWithLink(
        'If you have any questions about this request, ',
        'please contact us',
        '.',
        `${getEnv('ACCOUNTS_RFE_URL')}/contact`,
    );
    const deletionNote = createTextWithLink(
        'Please note that we still maintain your email address in our system. If you wish to completely remove all your data, you can',
        ' submit a full account deletion request',
        '. This is an irreversible action that will remove your account history. You will need to create a new account for future purchases.',
        'https://privacyportal.onetrust.com/webform/ad37f637-4936-48fb-9de5-eacfd28c9739/81ffeefa-aa44-4b28-9a0e-e8710790f056', // We don't have a staging form
    );

    if (isLoading || !data || (data && data.is404Error)) {
        return {
            title: 'Request Not Found',
            paragraph1: "We couldn't find the data deletion request you're looking for.",
            paragraph2:
                'If you recently submitted a data deletion request through Facebook, please try accessing it using the original link provided.',
            paragraph3: contactUsLink,
        };
    }

    const commonText = {
        title: 'Data Deletion Request Status',
        paragraph1: 'Dear Customer,',
        paragraph3: deletionNote,
        paragraph4: contactUsLink,
    };

    if (data.status === 'completed') {
        return {
            ...commonText,
            chip: {
                label: 'Completed',
                color: 'green',
            },
            paragraph2: getParagraph2(new Date(data.createdAt), true),
        };
    }
    return {
        ...commonText,
        chip: {
            label: 'Processing',
            color: 'orange',
        },
        paragraph2: getParagraph2(new Date(data.createdAt), false),
    };
}
