import { FC } from 'react';
import { Box, FormControlLabel, Stack, Toggle, Tooltip, Typography } from '@customink/pigment-react';
import { useAuth, useAuthUser } from '~/contexts/Auth/AuthContext';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';

const InkerExperienceToggle: FC = () => {
    const { isImpersonatedExperience, setImpersonatedExperience } = useAuth();
    return (
        <Box pl={1}>
            <Tooltip title="Turn off to force external experience, but continue impersonating.">
                <FormControlLabel
                    control={
                        <Toggle
                            checked={isImpersonatedExperience}
                            onChange={(e) => setImpersonatedExperience(e.target.checked)}
                        />
                    }
                    label="Inker experience"
                />
            </Tooltip>
        </Box>
    );
};

export const Impersonate: FC = () => {
    const user = useAuthUser();
    const showInkerExperienceToggle = useSignalmanFeatureFlag('inker_ux_toggle');
    if (!user.impersonated) {
        return null;
    }
    return (
        <Stack direction="row" gap={2} alignItems="baseline" flexWrap="wrap">
            <Typography variant="uiTextTiny">IMPERSONATING</Typography>
            <Typography variant="paragraphDefault" sx={{ fontWeight: 'bold' }}>
                {user.email}
            </Typography>
            {showInkerExperienceToggle && <InkerExperienceToggle />}
        </Stack>
    );
};
