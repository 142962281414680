import { DesignsPath } from '~/config';
import { ButtonLink } from '~/components/Link/Link';
import { useEffect } from 'react';
import { useLinks } from '~/contexts/LinkContext';
import { useRollbar } from '@rollbar/react';

export function PageNotFound() {
    const { contactLink } = useLinks();
    const rollbar = useRollbar();

    useEffect(() => {
        rollbar.error('404 Page not found', { page: window.location.pathname });
    }, []);
    return (
        <>
            <h1>404</h1>
            <h2>Oops, it seems this page doesn&apos;t exist.</h2>
            <p>
                If the problem persists,
                <a href={contactLink}> contact us</a>.
            </p>
            <ButtonLink to={DesignsPath}>Return To Your Account</ButtonLink>
        </>
    );
}
