import { FC, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box, OpenNewTabIcon, useMobileBreakpoint } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { useLinks } from '~/contexts/LinkContext';
import {
    AccountPathsGroup,
    ArtsPath,
    DesignsPath,
    FlagsPath,
    GroupOrdersPath,
    OrdersPath,
    PersonalSettingsPath,
    SharedDesignsPath,
    StoresPath,
} from '~/config';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';
import { useInfiniteStores } from '~/adapters/services/accounts/stores/hooks';
import { useSharedWithMeCount } from '~/adapters/services/collaboration/collaborationHooks';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { useAccount, useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { getImpersonateQueryVariable } from '~/adapters/browser/getImpersonateQueryVariable';
import { useInventoryRepository } from '~/adapters/services/inventory/rest';
import { useMatchMultiple } from '~/adapters/react-router/useMatchMultiple';
import setLocation from '~/adapters/browser/setLocation';
import { navigationWidth } from '~/components/Navigation/styles';
import { MenuItem } from './MenuItem';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const NavigationWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexBasis: navigationWidth,
        flexShrink: 0,
    },
}));

export const useShowDesktopNavigation = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up('lg'));
};

const Navigation: FC = () => {
    const matchMultiple = useMatchMultiple();
    const showDesktopMenu = useShowDesktopNavigation();
    const links = useLinks();
    const collaboration = useSignalmanFeatureFlag('collaboration');
    const isTeamAccountsFeatureFlagEnabled = useFeatureFlagEnabled('team_accounts_v0');
    const isSignalerFlagPageEnabled = useFeatureFlagEnabled('flags_page');
    const isImpersonateFundraisingEnabled = useFeatureFlagEnabled('impersonate_fundraising_v2');
    const { personal: isAccountPersonal } = useAccount();
    const isMobile = useMobileBreakpoint();
    const {
        user: { referenceUuid },
        account: { id: accountId },
    } = useUserAccount();

    const { data: storesData } = useInfiniteStores({ accountId }, { enabled: isAccountPersonal });
    const doesAccountHaveStores = Boolean(storesData?.pages[0].userInvitationToken);

    const { data: sharedWithMeCountData } = useSharedWithMeCount({
        // TODO https://customink.monday.com/boards/3548701582/pulses/5337293306 when the test is first rolled out, enable query for those not in test
        enabled: false,
        // enabled: !collaboration
    });
    const isSharedWithMe = useMemo(() => Boolean(sharedWithMeCountData?.resources.totalCount), [sharedWithMeCountData]);

    const { getInventoryRedirectURL } = useInventoryRepository();

    const handleInventoryLinkClick = async () => {
        const impersonateEmail = getImpersonateQueryVariable();
        if (impersonateEmail) {
            const link = await getInventoryRedirectURL({ uuid: referenceUuid });
            if (link) {
                setLocation(link);
                return;
            }
        }
        setLocation(links.inventoryLink);
    };

    const getImpersonateFundraisingPath = (): string => {
        const url = new URL(links.fundRaisingLink);

        const impersonateEmail = getImpersonateQueryVariable();
        if (impersonateEmail) {
            url.searchParams.set('email', impersonateEmail);
        }

        return url.toString();
    };

    const menu: MenuItem[][] = [
        [
            {
                selected: matchMultiple([DesignsPath, ArtsPath]),
                link: DesignsPath,
                action: 'saved designs',
                // In this context, nested ternary is by far the simplest way, and the case is simple enough to read
                /* eslint-disable-next-line */
                label: isAccountPersonal
                    ? 'My Designs'
                    : 'Team Designs'
            },
            {
                selected: matchMultiple(SharedDesignsPath),
                link: SharedDesignsPath,
                enabled: collaboration || isSharedWithMe,
                action: 'shared designs',
                label: isTeamAccountsFeatureFlagEnabled ? 'Shared With Me' : 'Shared With You',
            },
            {
                selected: matchMultiple(OrdersPath),
                link: OrdersPath,
                action: 'order history',
                label: 'Order History',
            },
            {
                selected: matchMultiple(GroupOrdersPath),
                link: GroupOrdersPath,
                action: 'group orders',
                label: 'Group Orders',
                enabled: isAccountPersonal,
            },
            {
                selected: false,
                link: isImpersonateFundraisingEnabled ? getImpersonateFundraisingPath() : links.fundRaisingLink,
                action: 'fundraising',
                label: 'Fundraising',
                external: true,
                enabled: isAccountPersonal,
            },
            {
                selected: matchMultiple(StoresPath),
                link: StoresPath,
                enabled: isAccountPersonal && doesAccountHaveStores,
                action: 'stores',
                label: 'Online Stores',
            },
            {
                selected: matchMultiple(AccountPathsGroup),
                link: PersonalSettingsPath,
                action: 'accounts',
                label: isTeamAccountsFeatureFlagEnabled && !isAccountPersonal ? 'Team Settings' : 'Account Settings',
            },
            {
                selected: matchMultiple(FlagsPath),
                link: FlagsPath,
                enabled: isSignalerFlagPageEnabled,
                action: 'signaler feature flags',
                label: '🚦 Feature flags',
            },
        ],
        [
            {
                selected: false,
                link: links.inventoryLink,
                enabled: !isMobile,
                action: 'inventory',
                label: 'Inventory',
                external: true,
                endIcon: <OpenNewTabIcon />,
                onClick: handleInventoryLinkClick,
            },
        ],
    ];
    return (
        <NavigationWrapper>
            {showDesktopMenu ? <DesktopNavigation menu={menu} /> : <MobileNavigation menu={menu.flat()} />}
        </NavigationWrapper>
    );
};

export default Navigation;
