import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { Card } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';

export const NoHoverCard = styled(Card)(({ theme }) => ({
    '&:hover': {
        boxShadow: 'none',
        transform: 'none',
        cursor: 'default',
        borderColor: designTokens.color.gray.accent.value,
    },
    '>div:last-of-type': {
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(2.5),
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(4),
        },
    },
}));
