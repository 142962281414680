import type { Client, FetchOptions } from 'openapi-fetch';
import type { PathsWithMethod, FilterKeys } from 'openapi-typescript-helpers';
import { ResponseError } from '~/adapters/openapifetch/responseError';

import { isObjectWithMessage } from '~/adapters/typescript/isObjectWithMessage';
import type { paths } from './schema';

interface ApiResponse {
    data?: unknown;
    error?: unknown;
    response: Response;
}

function isErrorWithMessage(error: unknown): error is { error: { message: string } } {
    return error !== null && typeof error === 'object' && 'error' in error && isObjectWithMessage(error.error);
}

export class AccountsApiClient {
    constructor(private readonly client: Client<paths>) {}

    public getPaginated = async <
        Path extends PathsWithMethod<paths, 'get'>,
        Init extends FetchOptions<FilterKeys<paths[Path], 'get'>>,
    >(
        path: Path,
        init: Init,
        currentPage: number,
        pageSize: number,
    ) => {
        const response = await this._get(path, init);
        const xTotalCount = response.response.headers.get('x-total-count');
        const totalCount = xTotalCount ? parseInt(xTotalCount, 10) : 0;
        const hasMorePages = currentPage * pageSize < totalCount;
        const totalPages = Math.ceil(totalCount / pageSize);
        return {
            currentPage,
            totalCount,
            totalPages,
            hasMorePages,
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            items: response.data!,
            headers: response.response.headers,
        };
    };

    private _get = async <
        Path extends PathsWithMethod<paths, 'get'>,
        Init extends FetchOptions<FilterKeys<paths[Path], 'get'>>,
    >(
        path: Path,
        init: Init,
    ) => {
        const response = await this.client.GET(path, { ...init });
        return this.checkResponse(response);
    };

    public get = async <
        Path extends PathsWithMethod<paths, 'get'>,
        Init extends FetchOptions<FilterKeys<paths[Path], 'get'>>,
    >(
        path: Path,
        init: Init,
    ) => {
        const response = await this._get(path, init);
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        return response.data!;
    };

    public post = async <
        Path extends PathsWithMethod<paths, 'post'>,
        Init extends FetchOptions<FilterKeys<paths[Path], 'post'>>,
    >(
        path: Path,
        init: Init,
    ) => {
        const response = await this.client.POST(path, { ...init });
        return this.checkResponse(response);
    };

    public put = async <
        Path extends PathsWithMethod<paths, 'put'>,
        Init extends FetchOptions<FilterKeys<paths[Path], 'put'>>,
    >(
        path: Path,
        init: Init,
    ) => {
        const response = await this.client.PUT(path, { ...init });
        return this.checkResponse(response);
    };

    public delete = async <
        Path extends PathsWithMethod<paths, 'delete'>,
        Init extends FetchOptions<FilterKeys<paths[Path], 'delete'>>,
    >(
        path: Path,
        init: Init,
    ) => {
        const response = await this.client.DELETE(path, { ...init });
        return this.checkResponse(response);
    };

    private checkResponse = <T extends ApiResponse>(response: T) => {
        if (response.error || !response.data) {
            const message = isErrorWithMessage(response.error)
                ? response.error.error.message
                : 'An error occurred during fetching data from Accounts Service server';
            throw new ResponseError(message, {
                cause: response.error,
                response: response.response,
            });
        }
        return response;
    };
}
