import { Stack } from '@customink/pigment-react';
import { VerifiedUser } from '@mui/icons-material';
import { StackProps, Typography } from '@mui/material';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { useRollbar } from '@rollbar/react';

export function ImpersonateOnlyBanner(props: StackProps) {
    const rollbar = useRollbar();
    const { impersonated } = useAuthUser();

    if (!impersonated) {
        rollbar.error(
            'Component `ImpersonateOnlyBanner` has been rendered although the user is not impersonated. This is probably a mistake.',
        );
        return null;
    }
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Stack {...props} direction="row" spacing={1} alignItems="center">
            <VerifiedUser color="secondary" />
            <Typography variant="uiTextTiny">Only impersonating Inkers can see this</Typography>
        </Stack>
    );
}
