import { useAuth, useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUsersRepository } from '~/adapters/services/accounts/users/rest';
import { ImpersonatedUserKey } from '~/adapters/services/profiles/admin/hooks';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useQueryParam } from '~/adapters/react-router/useQueryParam';

const userDataKey = 'user-data-key';
export const userAccountsKey = 'user-accounts-key';

export const useUserData = () => {
    const authUser = useOptionalAuthUser();
    const { getUserData } = useUsersRepository();

    return useQuery({
        queryKey: [userDataKey, authUser?.email],
        queryFn: () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return getUserData({ email: authUser!.email });
        },
        enabled: Boolean(authUser),
    });
};

export const useUserAccounts = () => {
    const { user } = useUserAccount();
    const { getUserAccounts } = useUsersRepository();

    return useQuery({
        queryKey: [userAccountsKey],
        queryFn: () => getUserAccounts(user.id),
        enabled: Boolean(user),
    });
};

export function useUpdateUserData() {
    const { updateUserData } = useUsersRepository();
    const { loginSilently } = useAuth();

    const client = useQueryClient();
    return useMutation({
        mutationFn: updateUserData,
        onSuccess: async () => {
            await Promise.all([
                client.invalidateQueries({ queryKey: [userDataKey] }),
                client.invalidateQueries({ queryKey: [ImpersonatedUserKey] }),
                loginSilently(),
            ]);
        },
    });
}

export const useUserSocialDeletionRequest = () => {
    const { getUserSocialDeletionRequest } = useUsersRepository();
    const id = useQueryParam('id');

    return useQuery({
        queryKey: [userDataKey, 'social-deletion-request', id],
        queryFn: () => getUserSocialDeletionRequest({ id: id ?? '' }),
    });
};
