import { useMemo } from 'react';
import { transformAccountDtoToAccount, transformAccountDtoToPersonalAccount } from '~/contexts/UserAccount/account';
import { useAccountsApiClient } from '../useAccountsApiClient';
import { CreateAccountRequest, RemoveUserFromAccountRequest, UpdateAccountRequest } from './types';
import { AccountsApiClient } from '../accountsApiClient';

class AccountsRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getPersonalAccount = async (email: string) => {
        const personalAccount = await this.client.get(`/accounts/personal`, {
            params: {
                query: {
                    email,
                },
            },
        });
        return transformAccountDtoToPersonalAccount(personalAccount);
    };

    public getAccount = async (accountId: number) => {
        const account = await this.client.get(`/accounts/{id}`, {
            params: {
                path: {
                    id: accountId,
                },
            },
        });
        return transformAccountDtoToAccount(account);
    };

    public updateAccount = async ({ accountId, ...body }: UpdateAccountRequest) => {
        const response = await this.client.put(`/accounts/{id}`, {
            params: {
                path: {
                    id: accountId,
                },
            },
            body,
        });
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        return transformAccountDtoToAccount(response.data!);
    };

    public createAccount = async (data: CreateAccountRequest) => {
        const response = await this.client.post(`/accounts`, {
            body: data,
        });
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        return transformAccountDtoToAccount(response.data!);
    };

    public getAccountUsers = (accountId: number) => {
        return this.client.get(`/accounts/{id}/users`, {
            params: {
                path: {
                    id: accountId,
                },
            },
        });
    };

    public removeAccountUser = async ({ accountId, userId }: RemoveUserFromAccountRequest) => {
        return this.client.delete(`/accounts/{id}/users/{userId}`, {
            params: {
                path: { id: accountId, userId },
            },
        });
    };
}

export const useAccountsRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new AccountsRepository(client), [client]);
};
