import { Box, Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { formatDate } from '~/adapters/date/date';
import { OrderSummary } from '~/adapters/services/accounts/orders/types';
import { Money } from '~/components/Money/money';
import { useRollbar } from '@rollbar/react';
import Rollbar from 'rollbar';
import { EmphasisText } from '../../../components/EmphasizeText';
import { InfoText } from '../../../components/InfoText';
import { AccountsPortalOrderState, getAccountsPortalOrderState } from '../../../lib/getAccountsPortalOrderState';

interface OrderHeaderRowProps {
    order: OrderSummary;
}

export const HeaderRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
    },
}));

export const HeaderRowBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(4),
    },
}));

export const HeaderRowBoxHiddenWhenSmall = styled(HeaderRowBox)(({ theme }) => ({
    [theme.breakpoints.down('md')]: { display: 'none' },
}));

export const OrderHeader = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

const StatusBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
        order: -1,
        marginBottom: theme.spacing(2),
    },
}));

function statusHelper(order: OrderSummary, rollbar: Rollbar): string {
    const apOrderState = getAccountsPortalOrderState(order);
    switch (apOrderState) {
        case AccountsPortalOrderState.Delivered:
            if (!order.deliveredAt) {
                rollbar.error(`Order ${order.id} delivered but no deliveredAt date`);
                return 'Delivered';
            }
            return `Delivered ${formatDate(order.deliveredAt)}`;
        case AccountsPortalOrderState.InProgress:
            return 'In Progress';
        default:
            return apOrderState.charAt(0).toUpperCase() + apOrderState.slice(1);
    }
}

export function OrderHeaderRow({ order }: OrderHeaderRowProps) {
    const rollbar = useRollbar();
    return (
        <OrderHeader>
            <HeaderRow>
                <HeaderRowBox>
                    <InfoText>Order</InfoText>
                    <EmphasisText>#{order.id}</EmphasisText>
                </HeaderRowBox>
                <HeaderRowBox>
                    <InfoText>Date placed</InfoText>
                    <EmphasisText>{formatDate(order.placedAt)}</EmphasisText>
                </HeaderRowBox>
                <HeaderRowBox>
                    <InfoText>Total</InfoText>
                    <EmphasisText>
                        <Money value={Number(order.totalPrice)} />
                    </EmphasisText>
                </HeaderRowBox>
            </HeaderRow>
            <StatusBox>
                <InfoText>{statusHelper(order, rollbar)}</InfoText>
            </StatusBox>
        </OrderHeader>
    );
}
