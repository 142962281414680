import React, { ReactNode, useState } from 'react';
import { usePage } from '~/adapters/browser/usePage';
import { useInfiniteOrders } from '~/adapters/services/accounts/orders/hooks';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import { OrderSortBy } from '~/adapters/services/accounts/orders/types';
import { Loader } from '~/components/Loader/loader';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useLinks } from '~/contexts/LinkContext';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { OrdersList } from './OrdersList/OrdersList';
import { SectionContent } from '../../components/Layout/SectionContent';

export interface Props {
    children?: ReactNode;
}

const Orders: React.FC<Props> = () => {
    const links = useLinks();
    const { id: accountId } = useAccount();
    const [sort, setSort] = useState<OrderSortBy>('-placedAt');

    usePage({ title: 'Order History', reportPath: '/accounts/orders' });

    const {
        data: ordersData,
        isFetching: isOrderFetching,
        hasNextPage: hasOrderNextPage,
        fetchNextPage: fetchNextOrderPage,
        isPlaceholderData: isOrderPreviousData,
        isLoading: isOrderLoading,
    } = useInfiniteOrders({ accountId, sort });
    const orders = useFlattenPaginatedData(ordersData, 'items');

    if (isOrderLoading) {
        return <Loader title="Loading..." devLabel="order API" />;
    }

    return (
        <ErrorBoundary errorMessage="An error occurred while retrieving your order history">
            <SectionContent>
                <OrdersList
                    orders={orders}
                    designLabLink={links.designLabLink}
                    loading={isOrderFetching}
                    onLoadMore={fetchNextOrderPage}
                    hasMorePages={hasOrderNextPage}
                    sort={sort}
                    onSortChange={setSort}
                    filtersChangedLoading={isOrderFetching && isOrderPreviousData}
                />
            </SectionContent>
        </ErrorBoundary>
    );
};

export default Orders;
