import Rollbar from 'rollbar';
import { Config } from '~/adapters/config/config';
import { knownAuthErrors } from '~/contexts/Auth/error';

const environment = import.meta.env.MODE;
const enabled = environment === 'staging' || environment === 'production';
const viteRelease = Config.VITE_RELEASE;

function isKnownAuthError(args: Rollbar.LogArgument[]): boolean {
    // eslint-disable-next-line no-restricted-syntax
    for (const arg of args) {
        if (arg instanceof Error && knownAuthErrors.includes(arg.message)) {
            return true;
        }
        if (typeof arg === 'string' && knownAuthErrors.includes(arg)) {
            return true;
        }
    }
    return false;
}

function shouldDropEvent(args: Rollbar.LogArgument[]): boolean {
    return isKnownAuthError(args);
}

export const rollbarConfig: Rollbar.Configuration = {
    accessToken: import.meta.env.ACCOUNTS_ROLLBAR_ACCES_KEY,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled,
    environment,
    hostSafeList:
        environment === 'staging' ? ['*.dev.customink.com', 'www-master.staging.customink.com'] : ['customink.com'],
    payload: {
        client: {
            javascript: {
                code_version: viteRelease,
                guess_uncaught_frames: true,
                source_map_enabled: true,
            },
        },
        code_version: viteRelease,
        environment,
    },
    checkIgnore: (_, args) => shouldDropEvent(args),
    ignoredMessages: [
        'Non-Error promise rejection captured',
        'ReferenceError: UET is not defined',
        'isProxied',
        'ChunkLoadError',
        'JSONP request failed.',
        /Loading chunk [\d]+ failed/,
    ],
};

export const rollbar = new Rollbar(rollbarConfig);
