import { FormEventHandler, useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Loader } from '~/components/Loader/loader';
import { useUpdateUserData } from '~/adapters/services/accounts/users/hooks';
import { sendChangeNameEvent } from '~/adapters/services/profiles/profile/tracking';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { useRollbar } from '@rollbar/react';

interface ChangeNameDialogProps {
    open: boolean;
    onClose: EmptyFunction;
    currentFirstName?: string | null;
    currentLastName?: string | null;
    userId: number;
}

const ChangeNameStack = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));

export function ChangeNameDialog({ currentFirstName, currentLastName, userId, onClose, open }: ChangeNameDialogProps) {
    const [firstName, setFirstName] = useState(currentFirstName ?? '');
    const [lastName, setLastName] = useState(currentLastName ?? '');
    const { mutate, isPending } = useUpdateUserData();
    const rollbar = useRollbar();

    const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
        (e) => {
            e.preventDefault();
            sendChangeNameEvent('Start');
            mutate(
                { firstName, lastName, userId },
                {
                    onSettled: onClose,
                    onError: (error) => {
                        sendChangeNameEvent('Error');
                        pushApiErrorNotification('Your name could not be updated right now.');
                        rollbar.error(error);
                    },
                    onSuccess: () => {
                        sendChangeNameEvent('Success');
                        pushSuccessNotification('Name was updated.');
                    },
                },
            );
        },
        [mutate, firstName, lastName, userId, onClose],
    );
    return (
        <Dialog
            open={open}
            onClose={onClose}
            title="Change Name"
            width="md"
            mobileVariant="fullScreen"
            disableRestoreFocus
        >
            <DialogContent pt={2}>
                <form id="change-name-form" onSubmit={handleSubmit}>
                    <ChangeNameStack>
                        <TextField
                            name="firstName"
                            label="First Name"
                            inputProps={{ autoComplete: 'given-name', 'data-testid': 'profile first name textfield' }}
                            value={firstName}
                            onChange={(ev) => setFirstName(ev.target.value)}
                            fullWidth
                            autoFocus
                        />
                        <TextField
                            name="lastName"
                            label="Last Name"
                            inputProps={{ autoComplete: 'family-name', 'data-testid': 'profile last name textfield' }}
                            value={lastName}
                            onChange={(ev) => setLastName(ev.target.value)}
                            fullWidth
                        />
                    </ChangeNameStack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="secondary">
                    Cancel
                </Button>
                <Button
                    data-testid="change name dialog button"
                    disabled={isPending}
                    type="submit"
                    form="change-name-form"
                >
                    {isPending ? <Loader title="Saving..." /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
