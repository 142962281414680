import { useMemo } from 'react';
import { ChangePasswordRequest, SetPasswordRequest } from '~/adapters/services/accounts/auth0/types';
import { useAccountsApiClient } from '~/adapters/services/accounts/useAccountsApiClient';
import { rollbar } from '~/adapters/rollbar';
import { AccountsApiClient } from '../accountsApiClient';

class Auth0Repository {
    constructor(private readonly client: AccountsApiClient) {}

    public verifyUser = (sessionToken: string) => {
        if (!sessionToken) {
            rollbar.error('Verify user should be always called with session token, but no sessionToken was given');
        }
        return this.client.post(`/auth0/verify`, {
            params: {
                query: {
                    sessionToken,
                },
            },
        });
    };

    public setPassword = (data: SetPasswordRequest) => {
        return this.client.post(`/auth0/set-password`, {
            method: 'POST',
            body: data,
        });
    };

    public changePassword = async (data: ChangePasswordRequest) => {
        return this.client.post(`/auth0/change-password`, {
            body: data,
        });
    };

    public getAuthUserDetail = () => {
        return this.client.get(`/auth0/user`, {});
    };
}

export const useAuth0Repository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new Auth0Repository(client), [client]);
};
