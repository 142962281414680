import { useRollbarContext } from '@rollbar/react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook that sets the Rollbar context to the current pathname
 * whenever the location changes.
 */
export const useRollbarPathContext = () => {
    const { pathname } = useLocation();
    useRollbarContext(pathname);
};
