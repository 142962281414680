import React from 'react';
import { createRoot } from 'react-dom/client';
import './stylesheet/application.css';
import '@customink/design-tokens/build/ci_light/css/variables.css';
import '@customink/pigment-react/lib/fonts.css';
import './adapters/newrelic/newrelic';
import App from './app';

// non-null assertion is officially recommended  https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root_accounts_ui')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
