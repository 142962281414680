import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getImpersonateQueryVariable } from '~/adapters/browser/getImpersonateQueryVariable';
import { AuthTokens, AuthUser, useAuth } from '~/contexts/Auth/AuthContext';
import { rollbar } from '~/adapters/rollbar';

type AccessTokenMode = {
    withAccessToken: true;
    withIdToken?: false;
};

type IdTokenMode = {
    withAccessToken?: false;
    withIdToken: true;
};

type AnonymousMode = {
    withAccessToken?: false;
    withIdToken?: false;
};

export type AuthMode = AccessTokenMode | IdTokenMode | AnonymousMode;

type ImpersonateOptions = { attachImpersonate: boolean };

type CreateApiCallOptions = Omit<AxiosRequestConfig, 'auth'> & ImpersonateOptions & { auth?: AuthMode };

export type ApiCallOptions = AxiosRequestConfig & Partial<ImpersonateOptions>;
export type ApiCallResponse<T = unknown> = AxiosResponse<T>;
export type ApiCallError = AxiosError;
export type ApiCall = <T>(url: string, options?: ApiCallOptions) => Promise<ApiCallResponse<T>>;
interface GetAuthorizationHeaderProps {
    url: string;
    getTokens: () => Promise<AuthTokens>;
    identityProvider: string;
    user?: AuthUser;
    authMode?: AuthMode;
    queryParams?: URLSearchParams | Record<string, unknown>;
    isPublic?: boolean;
}

export const GetAuthorizationHeader = async (props: GetAuthorizationHeaderProps) => {
    const { url, getTokens, identityProvider, user, authMode, queryParams: params, isPublic } = props;
    const isAuthenticated = Boolean(user);
    const withAccessToken = authMode?.withAccessToken ?? false;
    const withIdToken = authMode?.withIdToken ?? false;
    const getOptionalTokens = () => (isAuthenticated ? getTokens() : { idToken: undefined, accessToken: undefined });
    const reportToRollbar = (tokenType: string) =>
        rollbar.error(`Missing ${tokenType} in request to ${url}`, { params, identityProvider });
    const { accessToken, idToken } = await getOptionalTokens();
    if (withAccessToken) {
        if (accessToken) {
            return `Bearer ${accessToken}`;
        }
        if (isPublic) {
            return undefined;
        }
        reportToRollbar('access token');
    } else if (withIdToken) {
        if (idToken) {
            return `Bearer ${idToken}`;
        }
        if (isPublic) {
            return undefined;
        }
        reportToRollbar('id token');
    }
    return undefined;
};

/**
 * Create configurable axios API call function for a single API service.
 * @param baseUrl base URL of the API service
 * @param createApiCallOptions axios request config + the auth mode supported by the given API service
 */
export const useApiCall = (baseUrl: string, createApiCallOptions: CreateApiCallOptions): ApiCall => {
    const axiosInstance = axios.create();
    const { getTokens, identityProvider, user } = useAuth();

    /**
     * axios API call function
     * @param path path to a single API endpoint
     * @param currentOptions (ApiCallOptions) axios request config for the specific request + impersonate options
     */
    return async (path, currentOptions = {}) => {
        const { auth: authMode, headers: defaultHeaders, ...defaultReqOptions } = createApiCallOptions;
        const { headers: currentHeaders, data, attachImpersonate, ...currentReqOptions } = currentOptions;

        const url = baseUrl + path;

        const mergedOptions = { ...defaultReqOptions, ...currentReqOptions };

        const shouldAttachImpersonate = attachImpersonate ?? createApiCallOptions.attachImpersonate;
        const queryParams = new URLSearchParams(mergedOptions.params);
        const impersonate = getImpersonateQueryVariable();
        if (shouldAttachImpersonate && impersonate) {
            queryParams.append('impersonate', impersonate);
        }
        const authrizationToken = await GetAuthorizationHeader({
            url,
            getTokens,
            identityProvider,
            user,
            authMode,
            queryParams,
        });

        const mergedHeaders = {
            ...defaultHeaders,
            ...currentHeaders,
            Authorization: authrizationToken,
        };

        return axiosInstance.request({ headers: mergedHeaders, ...mergedOptions, url, data, params: queryParams });
    };
};
