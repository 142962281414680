import { Button, CardActions, CardContent, Chip, Skeleton, Stack, Typography } from '@customink/pigment-react';
import { Circle } from '@mui/icons-material';
import { getEnv } from '~/adapters/config/env';
import { useUserSocialDeletionRequest } from '~/adapters/services/accounts/users/hooks';
import { NoHoverCard } from '~/components/NoHoverCard/NoHoverCard';
import { useSocialDeletionRequestText } from '~/pages/SocialDeletionRequest/useSocialDeletionRequestText';

export function SocialDeletionRequest() {
    const { data, isLoading } = useUserSocialDeletionRequest();
    const texts = useSocialDeletionRequestText(data, isLoading);
    const singInLink = `${getEnv('ACCOUNTS_PROFILES_URL')}/users/sign_in`;
    const showSkeleton = isLoading && !data;
    // NOTE: Why do we need to change the z-index? The height of the card depends on screen size, and the result of the request. So it's hard to predict the height of the card.
    const skeletonZIndex = showSkeleton ? 10 : -1;
    const cardZIndex = showSkeleton ? -1 : 10;
    return (
        <NoHoverCard>
            <>
                <Skeleton
                    height="100%"
                    width="100%"
                    variant="rectangular"
                    sx={{ zIndex: skeletonZIndex, position: 'absolute' }}
                />
                <CardContent
                    sx={{ p: { sm: 2.5, md: 4 }, m: 0, pb: { sm: 0, md: 0 }, zIndex: cardZIndex, position: 'relative' }}
                >
                    <Stack direction="column">
                        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="start" mb={2}>
                            <Typography variant="heading4" fontWeight={700}>
                                {texts.title}
                            </Typography>
                            {texts.chip && (
                                <Chip
                                    component="span"
                                    icon={<Circle width="50px" style={{ color: texts.chip.color }} />}
                                    label={texts.chip.label}
                                />
                            )}
                        </Stack>
                        <Typography paragraph>{texts.paragraph1}</Typography>
                        <Typography paragraph>{texts.paragraph2}</Typography>
                        <Typography paragraph>{texts.paragraph3}</Typography>
                        {texts.paragraph4 && <Typography paragraph>{texts.paragraph4}</Typography>}
                    </Stack>
                </CardContent>
                <CardActions
                    sx={{
                        flexDirection: 'row',
                        p: { sm: 2.5, md: 4 },
                        pt: { sm: 0, md: 0 },
                        mt: 2.5,
                        zIndex: cardZIndex,
                        position: 'relative',
                    }}
                >
                    <Button href={singInLink}>Return to Your Account</Button>
                </CardActions>
            </>
        </NoHoverCard>
    );
}
