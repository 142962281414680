import { Tab, Tabs } from '@customink/pigment-react';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { useRouteTabs } from '~/components/hooks/useRouteTabs';
import { ArtsPath, DesignsPath } from '~/config';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { SavedDesigns } from './SavedDesigns/SavedDesigns';
import Arts from './Arts/Arts';

export function DesignsAndArts() {
    const [selectedPath, selectPath] = useRouteTabs(DesignsPath, ArtsPath);
    const { account } = useUserAccount();

    return (
        <>
            <PageHeader>{account.personal ? 'My Designs' : 'Team Designs'}</PageHeader>
            <ErrorBoundary errorMessage="An error occurred while retrieving the designs">
                <Tabs
                    value={selectedPath}
                    onChange={(_, newVal) => selectPath(newVal)}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tab label="Designs" value={DesignsPath} />
                    <Tab label="Uploads" value={ArtsPath} />
                </Tabs>
                {selectedPath === DesignsPath && <SavedDesigns />}
                {selectedPath === ArtsPath && <Arts />}
            </ErrorBoundary>
        </>
    );
}
