import { OrderSummary } from '~/adapters/services/accounts/orders/types';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { useLinks } from '~/contexts/LinkContext';
import { reorderLink } from '~/adapters/services/rfe/links';

export const useOrderLinks = (order: OrderSummary, reorderAddToCartEnabled = false) => {
    const { isImpersonatedExperience } = useAuth();
    const { rfeLink } = useLinks();

    const designUrl = isImpersonatedExperience
        ? `${rfeLink}/admin/designs/${order.designId}`
        : `${rfeLink}/designs/${order.designName}/${order.designId}-${order.account.id}/retrieve`;

    const reorderUrl = isImpersonatedExperience
        ? `${rfeLink}/admin/internal_checkouts/create_reorder_ap_order?design_id=${order.designId}&reorder=${order.id}`
        : reorderLink(order.id, reorderAddToCartEnabled);

    const trackOrderUrl = `${rfeLink}/track/${order.id}-${order.account.id}`;

    const invoiceUrl = `${rfeLink}/track/${order.id}-${order.account.id}/invoice`;

    return {
        designUrl,
        reorderUrl,
        trackOrderUrl,
        invoiceUrl,
    };
};
