import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0, User } from '@auth0/auth0-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { getImpersonateQueryVariable } from '~/adapters/browser/getImpersonateQueryVariable';
import { useIsLoadedWithinEmbassy } from '~/adapters/browser/embassy';
import { InternalTenantConnection } from '~/contexts/Auth/Auth0/InternalTenantConnection';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { INTERNAL_REQUIRED_LOGIN_ERROR, REDIRECTING_TO_LOGIN_ERROR } from '~/contexts/Auth/error';
import { Box } from '@customink/pigment-react';
import { Button } from '@mui/material';
import { useThrowToErrorBoundary } from '~/components/ErrorBoundary';
import { useAuth0ScreenHint } from '~/adapters/auth0/useAuth0ScreenHint';
import { useAuth0Error } from '~/adapters/auth0/useAuth0Error';
import { useTrackIdentityProvider } from '~/adapters/tracking/useTrackIdentityProvider';
import { useRollbarPerson } from '@rollbar/react';
import { AuthContext, AuthContextData, getTargetUserInfo } from '../AuthContext';

interface Auth0User extends User {
    internal?: boolean;
}

function getUserInfo(
    user: Auth0User | undefined,
    isInternal: boolean,
    isImpersonated: boolean,
    impersonateEmail?: string,
) {
    if (!user) {
        return {};
    }
    const userInfo = getTargetUserInfo(isImpersonated, user.email ?? '', impersonateEmail);
    return {
        id: user.sub ?? '',
        email: userInfo.email,
        isInternal,
        isAuthenticated: user.isAuthenticated,
        originalEmail: userInfo.impersonated?.originalEmail,
    };
}

export const Auth0UserProvider = ({ children }: PWC) => {
    const auth0 = useAuth0<Auth0User>();
    const throwToErrorBoundary = useThrowToErrorBoundary();
    const { search } = useLocation();
    const impersonateEmail = useMemo(getImpersonateQueryVariable, [search]);
    const { isInternalRequiredError } = useAuth0Error();
    const { screenHint } = useAuth0ScreenHint();

    if (isInternalRequiredError) {
        auth0.loginWithRedirect({
            authorizationParams: {
                prompt: 'login',
                connection: InternalTenantConnection,
            },
        });
        throw new Error(INTERNAL_REQUIRED_LOGIN_ERROR);
    }

    if (screenHint) {
        auth0.loginWithRedirect({
            authorizationParams: {
                screen_hint: screenHint,
            },
        });
        throwToErrorBoundary(new Error(REDIRECTING_TO_LOGIN_ERROR));
    }

    const isLoadedInEmbassy = useIsLoadedWithinEmbassy();
    const [isEmbassyLoginRequired, setEmbassyLoginRequired] = useState(false);

    const isTrackingCompleted = useTrackIdentityProvider(auth0.isLoading, auth0.isAuthenticated);

    const [isImpersonatedExperience, setImpersonatedExperience] = useState(false);

    const isInternal = auth0.user?.internal ?? false;
    const isImpersonated = Boolean(impersonateEmail && isInternal);

    useEffect(() => {
        if (isImpersonated) {
            setImpersonatedExperience(true);
        }
    }, [isImpersonated]);

    useRollbarPerson(getUserInfo(auth0.user, isInternal, isImpersonated, impersonateEmail));
    const auth0Context = useMemo<AuthContextData>((): AuthContextData => {
        return {
            user: auth0.user
                ? {
                      id: auth0.user.sub ?? '',
                      ...getTargetUserInfo(isImpersonated, auth0.user.email ?? '', impersonateEmail),
                      isInternal,
                      emailDerivedName: auth0.user.nickname ?? '',
                      fullName: auth0.user.name ?? '',
                      isAuthenticated: auth0.isAuthenticated,
                  }
                : undefined,
            isImpersonatedExperience,
            setImpersonatedExperience,
            identityProvider: 'auth0',
            loginWithPopup: async () => auth0.loginWithPopup(),
            loginWithRedirect: async () => {
                if (isLoadedInEmbassy) {
                    setEmbassyLoginRequired(true);
                    return;
                }
                await auth0.loginWithRedirect();
            },
            loginSilently: async () => {
                await auth0.getAccessTokenSilently({ detailedResponse: true });
            },
            logout: async () => auth0.logout({ logoutParams: { returnTo: import.meta.env.ACCOUNTS_AUTH0_LOGOUT_URL } }),
            getTokens: async () => {
                try {
                    const { id_token: idToken, access_token: accessToken } = await auth0.getAccessTokenSilently({
                        detailedResponse: true,
                    });
                    return { idToken, accessToken };
                } catch {
                    if (isLoadedInEmbassy) {
                        setEmbassyLoginRequired(true);
                    } else {
                        auth0.loginWithRedirect();
                    }
                    throwToErrorBoundary(new Error(REDIRECTING_TO_LOGIN_ERROR));
                    throw new Error(REDIRECTING_TO_LOGIN_ERROR);
                }
            },
        };
    }, [
        auth0.user,
        auth0.isAuthenticated,
        auth0.loginWithPopup,
        auth0.loginWithRedirect,
        auth0.logout,
        auth0.getAccessTokenSilently,
        impersonateEmail,
        isLoadedInEmbassy,
        isImpersonatedExperience,
    ]);

    if (auth0.isLoading) {
        return <FullLayoutLoader devLabel="Auth0 AuthProvider" />;
    }
    if (!isTrackingCompleted) {
        return <FullLayoutLoader devLabel="GA tracking of Identity Provider" />;
    }

    if (isEmbassyLoginRequired) {
        return (
            <Box sx={{ textAlign: 'center', pt: 4 }}>
                <Button
                    onClick={async () => {
                        await auth0.loginWithPopup({
                            authorizationParams: {
                                prompt: 'login',
                                connection: InternalTenantConnection,
                            },
                        });
                        setEmbassyLoginRequired(false);
                    }}
                >
                    Login as Inker
                </Button>
            </Box>
        );
    }

    return <AuthContext.Provider value={auth0Context}>{children}</AuthContext.Provider>;
};
