import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { Box, Container } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { PWC } from '~/adapters/typescript/propsWithChildren';

const GreyBox = styled(Box)(() => ({
    backgroundColor: designTokens.color.gray.light.value,
}));

export function GreyPageWithCenteredContainer({ children }: PWC) {
    return (
        <GreyBox>
            <Container
                maxWidth="lg"
                sx={{
                    p: {
                        sm: 3,
                        md: 8,
                    },
                }}
            >
                {children}
            </Container>
        </GreyBox>
    );
}
