import { useUpdateUserData } from '~/adapters/services/accounts/users/hooks';
import { useCallback } from 'react';
import { DestructiveButton } from '~/components/DestructiveButton';
import { pushSuccessNotification } from '~/adapters/notistack/notistack';
import { Loader } from '~/components/Loader/loader';
import { useRollbar } from '@rollbar/react';

interface DeletePhoneButtonProps {
    userId: number;
}

export const DeletePhoneButton = ({ userId }: DeletePhoneButtonProps) => {
    const { mutate, isPending } = useUpdateUserData();
    const rollbar = useRollbar();
    const handleDeleteTelephone = useCallback(() => {
        mutate(
            { telephone: null, userId },
            {
                onSuccess: () => {
                    pushSuccessNotification('Phone number was removed.');
                },
                onError: (error) => {
                    pushSuccessNotification('Phone number could not be removed right now.');
                    rollbar.error(error);
                },
            },
        );
    }, [mutate]);

    return (
        <DestructiveButton onClick={handleDeleteTelephone}>
            {isPending ? <Loader title="Pending..." /> : 'Remove'}
        </DestructiveButton>
    );
};
