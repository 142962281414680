import { Auth0Error, Auth0Exception } from '~/adapters/services/accounts/auth0/types';

export class ResponseError extends Error {
    public readonly response: Response;

    constructor(message: string, responseError: { cause: unknown; response: Response }) {
        super(message);
        this.cause = responseError.cause;
        this.response = responseError.response;
    }

    public isStatusCode(statusCode: number): boolean {
        return this.response.status === statusCode;
    }
}

export const isResponseError = (error: unknown): error is ResponseError => error instanceof ResponseError;

export const isResponseAuth0Error = (
    error: unknown,
): error is ResponseError & {
    cause: {
        error: Auth0Error;
    };
} => {
    if (isResponseError(error) && typeof error.cause === 'object' && error.cause !== null && 'error' in error.cause) {
        const auth0Error = error.cause.error as Auth0Error;
        if (!auth0Error || !auth0Error.type) {
            return false;
        }
        return Auth0Exception.includes(auth0Error.type);
    }
    return false;
};
